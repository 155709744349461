import {ConversionMap, ConversionFunction} from "lib/types/import"
import {many, one} from "lib/import/extract"
import {alphanumeric, bool, numeric} from "lib/import/convert"
import {Communication, CommunicationDocument, CustomerDocument, PolicyDocument, ClaimDocument, Attachment, PolicyDocumentAegon, AttachmentAegonResponse} from "./types"
import {epochDate} from "leven-shared-components/typings/convert"
import {Message, FAQ, Maintenance, AegonDocumentResponse} from "api/models/communications"

const required = true
const optional = false

function filterTempNullDates(policyComm: any) {
	for (const pol of policyComm) {
		pol.myCommunications = pol.myCommunications.filter((c: any) => c.dateSent !== null)
	}
	return policyComm
}

const defineSubject = (subject?: string) => subject ? subject.includes("Extern document") ? "Bericht met bijlage" : subject : "-"

const documentMap: ConversionMap<CommunicationDocument> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	communicationId: ["communicationId", numeric, required],
	sendDate: ["dateSent", epochDate, required],
	subject: ["attachments", defineSubject, optional],
	content: ["nonexistent", () => undefined, optional],
	attachments: ["nonexistent", () => undefined, optional]
}

const customerDocument: ConversionMap<CustomerDocument> = documentMap
const customerCommunications = many(customerDocument)
export const convertCustomerCommunication: ConversionFunction<Array<CustomerDocument>> = value => {
	if (value && value.myCommunicationMinimalValueObjects) {
		// Remove after fix Axon bug (null dateSent should not be possible)
		const tempEndDateCheck = value.myCommunicationMinimalValueObjects.filter((obj: any) => obj.dateSent !== null)
		return customerCommunications(tempEndDateCheck)
	}
	return undefined
}

const policyDocument: ConversionMap<PolicyDocument> = {
	policyIdentifier: ["nonexistent", () => "", required], // This value is set below, after mapping.
	...documentMap
}

const policyCommunication: ConversionMap<Communication<PolicyDocument>> = {
	documents: ["myCommunications", many(policyDocument), required],
	identifier: ["policyIdentifier", alphanumeric, required]
}

const policyCommunications = many(policyCommunication)
export const convertPolicyCommunication: ConversionFunction<Array<PolicyDocument>> = value => {
	if (value && value.myPolicyCommunicationsValueObjects) {
		// Remove filterTempNullDates after fix Axon bug (null dateSent should not be possible)
		const communications = policyCommunications(filterTempNullDates(value.myPolicyCommunicationsValueObjects))
		// Return the policy documents, extended with the policy identifier.
		return communications && communications.flatMap(
			communication => communication.documents.map(
				document => ({...document, policyIdentifier: communication.identifier})
			)
		)
	}
	return undefined
}

const convertAegonDateMap: ConversionFunction<Date> = value => {
	if (value) {
		return new Date(value)
	}
	return undefined
}

const policyDocumentAegonMap: ConversionMap<PolicyDocumentAegon> = {
	policyIdentifier: ["polisNummer", alphanumeric, required],
	communicationIdentifier: ["documentId", alphanumeric, required],
	date: ["tekenDatum", convertAegonDateMap, required],
	subject: ["documentOmschrijving", defineSubject, required]
}

const policyDocumentsWrapperAegonMap: ConversionMap<AegonDocumentResponse> = {
	relatieNummer: ["relatieNummer", alphanumeric, required],
	documenten: ["documenten", many(policyDocumentAegonMap), required]
}

const policyDocumentsAegonMapper = one(policyDocumentsWrapperAegonMap)

export const convertPolicyDocumentAegon: ConversionFunction<AegonDocumentResponse> = value => {
	if (value) {
		return policyDocumentsAegonMapper(value)
	}
	return undefined
}

const attachmentAegonMap: ConversionMap<AttachmentAegonResponse> = {
	relationalIdentifier: ["relatieNummer", alphanumeric, required],
	policyIdentifier: ["polisNummer", alphanumeric, required],
	communicationIdentifier: ["documentId", alphanumeric, required],
	name: ["naam", alphanumeric, required],
	description: ["omschrijving", alphanumeric, required],
	date: ["tekenDatum", convertAegonDateMap, required],
	format: ["formaat", alphanumeric, required],
	file: ["inhoud", value => value, required]
}

const attachmentAegonMapper = one(attachmentAegonMap)

export const convertAttachmentAegon: ConversionFunction<AttachmentAegonResponse> = value => {
	if (value) {
		return attachmentAegonMapper(value)
	}
	return undefined
}

const claimDocument: ConversionMap<ClaimDocument> = {
	claimIdentifier: ["nonexistent", () => "", required], // This value is set below, after mapping.
	...documentMap
}

// Repeated code for claim communication. This could be optimized but it would become even less readable.
const claimCommunication: ConversionMap<Communication<ClaimDocument>> = {
	documents: ["myCommunications", many(claimDocument), required],
	identifier: ["claimIdentifier", alphanumeric, required]
}
const claimCommunications = many(claimCommunication)
export const convertClaimCommunication: ConversionFunction<Array<ClaimDocument>> = value => {
	if (value && Array.isArray(value.myClaimCommunicationsValueObjects)) {
		const communications = claimCommunications(value.myClaimCommunicationsValueObjects)
		return communications && communications.flatMap(
			communication => communication.documents.map(
				document => ({...document, claimIdentifier: communication.identifier})
			)
		)
	}
	return undefined
}

const attachment: ConversionMap<Attachment> = {
	externalIdentifier: ["documentExternalIdentifier", alphanumeric, required],
	category: ["documentCategoryExternalIdentifier", alphanumeric, required],
	description: ["documentDescription", alphanumeric, optional],
	type: ["documentTypeName", alphanumeric, required],
	hasPdfContent: ["hasPDFContent", bool, required],
	communicationExtId: ["communicationExternalIdentifier", alphanumeric, required],
	fileName: ["documentFileName", alphanumeric, optional]
}
const attachments = many(attachment)
export const convertAttachments: ConversionFunction<Array<Attachment>> = value => {
	if (value && Array.isArray(value.listOfMyCommunicationDocuments)) {
		return attachments(value.listOfMyCommunicationDocuments)
	}
	return undefined
}

const message: ConversionMap<Message> = {
	id: ["id", alphanumeric, required],
	order: ["order", numeric, required],
	title: ["title", alphanumeric, required],
	text: ["text", alphanumeric, required]
}

const messages = many(message)
export const convertMessages: ConversionFunction<Array<Message>> = value => messages(value)?.sort((a, b) => a.order < b.order ? -1 : 1)

const faq: ConversionMap<FAQ> = {
	id: ["id", alphanumeric, required],
	order: ["order", numeric, required],
	title: ["title", alphanumeric, required],
	text: ["text", alphanumeric, required],
	topic: ["topic", alphanumeric, optional]
}

const faqs = many(faq)
export const convertFAQs: ConversionFunction<Array<FAQ>> = value => faqs(value)?.sort((a, b) => a.order < b.order ? -1 : 1)

const maintenance: ConversionMap<Maintenance> = {
	active: ["active", bool, required],
	text: ["text", alphanumeric, required]
}

export const convertMaintenance = one(maintenance)
