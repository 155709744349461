<template>
	<div>
		<information-modal />
		<label class="input-label">Upload bijlage<span v-if="filesRequired"> *</span></label>
		<div class="dropzone" @dragover.prevent @drop="onDrop">
			<svg height="30px" width="30px" id="icoon_draganddrop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 20.45">
				<path d="M27,20.45H1a1,1,0,0,1-1-1v-10a1,1,0,0,1,2,0v9H26v-9a1,1,0,0,1,2,0v10A1,1,0,0,1,27,20.45Z"/>
				<path d="M19.66,8.24a1,1,0,0,0-1.42,0L15,11.49V1a1,1,0,0,0-2,0V11.49L9.76,8.24A1,1,0,0,0,8.34,9.66l4.95,4.95a1,1,0,0,0,1.42,0l4.95-4.95A1,1,0,0,0,19.66,8.24Z"/>
			</svg>
			<template>
				<p class="dropzone__title mobile__hide">Sleep hier uw bestand heen</p>
				<label class="dropzone__label">
					<span class="mobile__hide">Of blader door uw bestanden</span>
					<span class="mobile__show">Blader door uw bestanden</span>
					<input ref="fileInput" value="" class="dropzone__input displayhidden-ariavisible" type="file" multiple @change="onChange" @click="fileInputClicked">
				</label>
			</template>
			<div v-for="(file, index) in fileList" class="dropzone__file" :key="index">
				<span :class="!file.valid ? 'file-error' : ''">{{ file.file.name }}</span>
				<span @click="removeFile(file)" class="file__remove">
					<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 13 13">
						<path class="fill" fill="#000" fill-rule="evenodd" d="M8.62132034 6.5l3.18198056 3.18198052c.5857864.58578648.5857864 1.53553388 0 2.12132038-.5857865.5857864-1.5355339.5857864-2.12132038 0L6.5 8.62132034 3.31801948 11.8033009c-.58578643.5857864-1.5355339.5857864-2.12132034 0-.58578644-.5857865-.58578644-1.5355339 0-2.12132038L4.37867966 6.5 1.19669914 3.31801948c-.58578644-.58578643-.58578644-1.5355339 0-2.12132034.58578644-.58578644 1.53553391-.58578644 2.12132034 0L6.5 4.37867966l3.18198052-3.18198052c.58578648-.58578644 1.53553388-.58578644 2.12132038 0 .5857864.58578644.5857864 1.53553391 0 2.12132034L8.62132034 6.5z"/>
					</svg>
				</span>
			</div>
		</div>
		<p v-if="hasInvalidFiles && allowedTypes" class="file-error">U heeft ongeldige documenten toegevoegd. Verwijder de documenten en voeg a.u.b. geldige toe. De geldige extensies zijn: {{ allowedTypes.join(", ") }}</p>
		<p v-if="totalFilesToBig" class="file-error">De totale grootte van de documenten overschrijdt de limiet. U kunt maximaal een totaal van 30 mb uploaden.</p>
		<div class="radio-box input-box" v-if="requiredDocuments && requiredDocuments.length">
			<p><strong>Benodigde stukken</strong></p>
			<p>Voor deze aanvraag hebben wij onderstaande stukken nodig. Vink de stukken aan die u uploadt. U kunt de aanvraag pas versturen als u alles heeft afgevinkt.</p>

			<div class="checkbox-tooltip" v-for="(document, index) in requiredDocuments" :key="index">
				<div>
					<input type="checkbox" :id="`doc_${index}`" :value="document.title" v-model="checkedDocuments" @change="explainCheckedDocument($event, document, index, `doc_${index}`)">
					<label :for="`doc_${index}`">{{ document.title }}</label>
					<span v-if="document.helpText">
						<span
							v-if="isSelfService"
							tabindex="0"
							role="tooltip"
							@click="emitInformationText(document, `doc_${index}`)"
							@keydown.enter.prevent="emitInformationText(document, `doc_${index}`)"
						>
							<information-image />
						</span>
						<span v-else>
							<div class="tooltip">
								<information-image />
								<span class="tooltiptext" v-html="document.helpText"></span>
							</div>
						</span>
					</span>
				</div>
			</div>

			<accessible-error
				:show-error="submitted && !uploadedRequiredFile"
				:error-message="uploadedRequiredFileError"
			/>
			<accessible-error
				:show-error="submitted && !checkedAllFiles"
				:error-message="checkedAllFilesError"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { FileData, FilesToUpload } from "./typings/interfaces"
	import { sumBy } from "lodash-es"
	import { Prop, Watch } from "vue-property-decorator"
	import InformationImage from "leven-shared-components/InformationImage.vue"
	import InformationModal from "leven-shared-components/InformationModal.vue"
	import eventBus from "lib/vue/eventBus"
	import AccessibleError from "./AccessibleError.vue"

	interface Document {
		title: string
		documentType?: DocumentType
		helpText?: string
		infoComponent?: string
	}

	@Component({
		components: {
			InformationModal,
			InformationImage,
			AccessibleError
		}
	})
	export default class MultipleFileUploader extends Vue {
		@Prop({type: Array, required: true}) requiredDocuments!: Array<Document>
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Boolean, required: true}) isSelfService!: boolean

		fileSize: string = ""
		fileBase64: string | ArrayBuffer | null = ""
		tempFileBase: string = ""
		fileList: Array<FileData> = []

		allowedTypes = ["pdf", "doc", "tiff", "tif", "jpg", "jpeg", "docx", "msg", "PDF", "DOC", "TIFF", "TIF", "JPG", "JPEG", "DOCX", "MSG"]
		maxFileTotal = 30000000

		hasInvalidFiles = false
		totalFilesToBig = false
		filesRequired = false
		uploadedRequiredFile = false
		uploadedRequiredFileError = "Upload de benodigde stukken a.u.b. als bijlage."
		checkedAllFiles = false
		checkedAllFilesError = "U heeft nog niet alle benodigde stukken aangevinkt."

		checkedDocuments: Array<string> = []
		explainedDocuments: Array<number> = []

		@Watch("requiredDocuments", { deep: true, immediate: true })
		checkRequiredDocuments() {
			this.filesRequired = this.requiredDocuments && this.requiredDocuments.length > 0
			this.validateFiles()
		}

		onDrop(e: DragEvent) {
			e.stopPropagation()
			e.preventDefault()

			if (e.dataTransfer && e.dataTransfer.files) {
				this.addFiles(e.dataTransfer.files)
			}
		}

		onChange(e: Event) {
			const input = e.target as HTMLInputElement
			if (input.files) {
				this.addFiles(input.files)
			}
		}

		addFiles(files: FileList) {
			/* tslint:disable:prefer-for-of */
			for (let i = 0; i < files.length; i++) {
				this.createFile(files[i])
			}
		}

		createFile(file: File) {
			if (this.validFile(file)) {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => (
					this.fileSize = this.formatBytes(Number(file.size), 0),
					this.tempFileBase = reader.result ? reader.result.toString().replace(/^data:.+;base64,/, "") : "",
					this.fileBase64 = this.tempFileBase,
					this.fileList.push({ file, fileBase64: this.fileBase64, valid: true })
				)
			} else {
				this.fileList.push({ file, fileBase64: "", valid: false })
			}
		}

		removeFile(file: FileData) {
			const index = this.fileList.indexOf(file, 0)
			if (index > -1) {
				this.fileList.splice(index, 1)
			}
		}

		emitInformationText(document: Document, initiator: string) {
			eventBus.emit("informationModal", "Toelichting", undefined, document.helpText, document.infoComponent, initiator)
		}

		@Watch("fileList", {deep: true, immediate: false})
		changedFileList() {
			this.validateFiles()
		}

		@Watch("checkedDocuments", {deep: true, immediate: false})
		changedCheckedDocuments() {
			this.validateFiles()
		}

		explainCheckedDocument(event: MouseEvent, document: Document, index: number, initiator: string) {
			if (document.title.includes("identiteitsbewijs")) {

				if (!this.explainedDocuments.includes(index)) {
					const infoText = `Voor de afhandeling van uw verzoek hebben wij een duidelijk leesbare kopie van een geldig identiteitsbewijs nodig. In verband met de privacywetgeving vragen wij u om de volgende gegevens af te schermen: het BSN (ook in de cijferreeks onderaan) en de pasfoto. Is het kopie identiteitsbewijs niet afgeschermd, dan nemen wij uw verzoek niet in behandeling.<br><br>Op de <a href="https://www.rijksoverheid.nl/onderwerpen/identiteitsfraude/vraag-en-antwoord/veilige-kopie-identiteitsbewijs" target="_blank">website</a> van de Rijksoverheid leest u hoe u een veilige kopie van het identiteitsbewijs maakt.`

					this.explainedDocuments.push(index)

					eventBus.emit("informationModal", "Graag even uw aandacht", document.title, infoText, undefined, initiator)
				}
			}
			this.$emit('requiredFileChecked', { event, textContent: document.documentType ?? 'document' })
		}

		validateFiles() {

			this.hasInvalidFiles = this.fileList.filter(file => !file.valid).length > 0

			const validFiles = this.fileList.filter(file => file.valid)

			const total = sumBy<FileData>(validFiles, file => file.file.size)
			this.totalFilesToBig = total > this.maxFileTotal

			const filesToUpload: FilesToUpload = {
				listOfDocuments: []
			}

			this.uploadedRequiredFile = this.filesRequired ? validFiles.length > 0 : true
			this.checkedAllFiles = this.filesRequired ? this.requiredDocuments.length === this.checkedDocuments.length : true

			const filesValid = !this.hasInvalidFiles && !this.totalFilesToBig && this.uploadedRequiredFile && this.checkedAllFiles

			for (const file of this.fileList) {
				filesToUpload.listOfDocuments.push({ fileName: file.file.name, contents: file.fileBase64 })
			}

			this.$emit("changedFiles", { filesToUpload, filesValid, checkedDocuments: this.checkedDocuments })
		}

		emitError(errorMessage: string) {
			this.$emit('error', { componentUid: 'benodigde stukken', errorMessage })
		}

		validFile(file: File) {
			const fileExtension = file.name.split(".").pop()
			return this.allowedTypes.includes(fileExtension!)
		}

		formatBytes(bytes: number, decimals = 2) {
			if (bytes === 0) { return "0 Bytes" }

			const k = 1024
			const dm = decimals < 0 ? 0 : decimals
			const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

			const i = Math.floor(Math.log(bytes) / Math.log(k))

			return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
		}

		fileInputClicked() {
			this.resetFileInput()
			this.$emit('focus', { label: "Upload bijlage", isRequired: this.filesRequired })
		}

		resetFileInput() {
			const fileInput = this.$refs.fileInput as HTMLInputElement
			if (fileInput) {
			 	fileInput.value = ""
			}
		}

		@Watch("submitted")
		@Watch("uploadedRequiredFile")
		@Watch("checkedAllFiles")
		checkErrors() {
			if (this.submitted) {
				if (!this.uploadedRequiredFile) {
					this.emitError(this.uploadedRequiredFileError)
				}
				if (!this.checkedAllFiles) {
					this.emitError(this.checkedAllFilesError)
				}
			}
		}
	}
</script>
<style>
	@import "./assets/multiplefileuploader.css";
</style>
